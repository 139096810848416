<template>
<!-- <LoadingOverlay :show="store.state.beforeLoad"/> -->
<whiteOverlay :show="store.state.beforeLoad"/>

  <div class="Tree" :style="[store.state.movePlace ? {top: '38px', padding: '14px 16px 0px' } : null]">
    <div class="Tree__panel">
      <h2 class="Tree__title page_header" @click="here" v-if="!store.state.movePlace && store.state.beforeLoad">Select Location</h2>
    </div>

    <div class="Tree__main">
      <tree-item v-if="store.state.beforeLoad" :setAsStart="{ clickHandler: setAsStart }" :addFavourite="{ clickHandler: addFavourite }"
        :createSos="{ clickHandler: createSos }" :addLocation="{ clickHandler: addLocation }"
        :addLibrary="{ clickHandler: addLibrary }" :delLibrary="{ clickHandler: delLibrary }"
        :moveUp="{ clickHandler: moveUp }" :moveDown="{ clickHandler: moveDown }"
        :editRecord="{ clickHandler: editRecord }" :duplicateRecord="{ clickHandler: duplicateRecord }"
        :deleteRecord="{ clickHandler: deleteRecord }" :node="emptyRoot" isFirstItem :isEditMode=false ref="childComponent"
        @onClick="nodeWasClicked" />
      <tree-item v-if="store.state.beforeLoad" :setAsStart="{ clickHandler: setAsStart }" :addFavourite="{ clickHandler: addFavourite }"
        :createSos="{ clickHandler: createSos }" :addLocation="{ clickHandler: addLocation }"
        :addLibrary="{ clickHandler: addLibrary }" :delLibrary="{ clickHandler: delLibrary }"
        :moveUp="{ clickHandler: moveUp }" :moveDown="{ clickHandler: moveDown }"
        :editRecord="{ clickHandler: editRecord }" :duplicateRecord="{ clickHandler: duplicateRecord }"
        :movePlace = {movePlace}
        :deleteRecord="{ clickHandler: deleteRecord }" :node="root" isFirstItem :isEditMode=false ref="childComponent"
        @onClick="nodeWasClicked" @onMoveClick="onMoveClicked"/>
    </div>

    <div class="edit_section" @click.stop v-if="editLocation">
      <p class="dialog_header">edit</p>
      <h3>Edit Location</h3>

      <span class="charcount">{{ state.nodeName.length }}/45</span>
      <input type="text" class="edit_input" id="edit" maxlength="45" v-model="state.nodeName" @keyup="state.enableSubmit = true;" />

      <!-- <div class="visible">
        <label>
          <input type="checkbox" name="visible_edit" checked>
          Make Visible to Users
          <i class="checkbox"></i>
        </label>
      </div>

      <label class="delete_two">
        <input type="radio" name="delete" id="delete_two" value="0" />
        <p>Move location</p>
        <div class="choose_section">
          <input @click="sectionRelocate = !sectionRelocate" type="text" :placeholder="state.ifNewSectionSelected"/>
          <div @click="sectionRelocate = !sectionRelocate"
            style="height: 40px;line-height: 40px;border-radius: 8px;background-color: white;text-align: center;font-size: 14px;line-height:40px;">
            {{ state.selectVar }}</div>
          <transition name="slide-fade-more">
            <div class="section_list" v-if="sectionRelocate">
              <p v-for="(location, index) in filteredList" :key="index" v-text="location.name"
                @click="moveTo(location, false); sectionRelocate = !sectionRelocate; state.ifNewSectionSelected = location.name;">
              </p>
            </div>
          </transition>
        </div>
      </label> -->

      <div class="buttons">
        <btn class="button secondary" v-text="'cancel'"
          @click.stop="editLocation = false; sectionRelocate = false; state.selectVar = 'Select...'"></btn>
        <btn class="button" v-text="'save'"
          :class="{ 'disabled': state.replaceLocation === false && state.enableSubmit === false }" @click="moveNode()">
        </btn>
      </div>
    </div>

    <div class="add_section" @click.stop v-if="addNewLocation">
      <p class="dialog_header dialog_header_long">add location</p>
      <h3>Add Location</h3>

      <div>
        <span class="charcount">{{state.newLocationName.length }}/45</span>
        <input type="text" class="edit_input" id="add_location" maxlength="45" v-model="state.newLocationName" placeholder="Enter Name" />
      </div>

      <!-- <div class="visible">
        <label>
          <input type="checkbox" name="visible_edit" checked>
          Make Visible to Users
          <i class="checkbox"></i>
        </label>
      </div> -->

      <div class="buttons">
        <btn class="secondary" v-text="'cancel'" @click.stop="addNewLocation = false;"></btn>
        <btn v-text="'add location'" :class="{ 'disabled': state.newLocationName === '' }"
          @click="createNode()"></btn>
      </div>
    </div>

    <div class="delete_section_with" v-if="deleteNotEmpty">
      <p class="dialog_header">delete</p>
      <h3>Location has content!</h3>
      <p class="functional">Select what you want to do with existing content.   If you delete this location, all content will be moved to the Archived Items location.</p>

      <div class="option">
<!--        wtf-->
        <input type="radio" name="delete" id="delete_one" @click="isDeleteChecked = false;"/>
        <label class="delete_one" for="delete_one"></label>
        <span class="radiobutton" style="top: 17px"></span>
        <p class="functional">Delete location and content</p>
        <input style="z-index: 3; font-size: 1rem" type="text" name="delete_pattern" @keyup="check()" placeholder="Type DELETE to confirm">
      </div>
      
      <div class="option">
          <input type="radio" name="delete" id="delete_two" @click="isDeleteChecked = true;"/>
        <label class="delete_two" for="delete_two"></label>
        <input type="radio" name="delete" id="delete_two" @click="isDeleteChecked = true;"/>
        <span class="radiobutton" style="top: 25px"></span>
        <p class="functional">Delete location but move content to another location</p>
        <btn v-if="store.state.radioButtonId === null && isDeleteChecked" class="secondary" v-text="'select location'" style="height: 37px; line-height: 37px; text-transform: capitalize;" @click="store.state.modalToComeback = deleteNotEmpty; deleteNotEmpty = false; store.state.movePlace = true; state.deleteOption = 1; "></btn>
        <btn v-if="store.state.radioButtonId !== null && isDeleteChecked" class="secondary" v-text="'change location'" style="height: 37px; line-height: 37px; text-transform: capitalize;" @click="store.state.modalToComeback = deleteNotEmpty; deleteNotEmpty = false; store.state.movePlace = true; state.deleteOption = 1; "></btn>
      </div>
        <!-- <div class="choose_section">
          <input @click="sectionRelocate = !sectionRelocate" type="text" :placeholder="state.ifNewSectionSelected" />
          <p @click="sectionRelocate = !sectionRelocate" v-text="state.ifNewSectionSelected"></p>
          <transition name="slide-fade-more">
            <div class="section_list" v-if="sectionRelocate">
              <p v-for="(location, index) in filteredList" :key="index" v-text="location.name"
                @click="moveTo(location, true); sectionRelocate = !sectionRelocate; state.ifNewSectionSelected = location.name;">
              </p>
            </div>
          </transition>
        </div> -->

      <div class="buttons">
        <btn v-text="'delete'" :class="{ 'disabled': state.deleteOption === 0 }"    @click="deleteNode()"></btn>
        <btn class="secondary" v-text="'cancel'" @click.stop="store.state.radioButtonId = null; store.state.moveToSelect = []; store.state.moveId = null; deleteNotEmpty = false; sectionRelocate = false; isDeleteChecked = false;"></btn>

      </div>
    </div>

    <div class="delete_anything" @click.stop v-if="deleteLib">
      <h3>Library Deletion Warning!</h3>
      <div class="trash_can"></div>
      <div class="warn functional">
        If you delete this library, all sections, books and pages that are contained within the library will be DELETED. 
        <br/><br/>
        If you are sure, select the green button below.
      </div>
      <div class="buttons">
        <btn class="button" v-text="'delete lib'" @click.prevent="delThisLib"></btn>
        <btn class="button secondary" v-text="'cancel'" @click="deleteLib = false; deleteLibNode = undefined;"></btn>
      </div>
    </div>

    <div class="duplicate_modal" v-if="duplicateModal">
      <p class="dialog_header">Duplicate</p>
      <h3>Location Duplication</h3>
      <p class="functional">You have three options for duplication:</p>

      <transition v-for="type in ['noContent','linkContent','copyContent']">
        <div class="option">
          <input type="radio" name="duplicate" :id="'duplicate_' + type" />
          <label :for="'duplicate_' + type" @click="store.state.duplicateType = type"></label>
          <span class="radiobutton"></span>
          <p v-if="type === 'noContent'" class="functional">Duplicate the location, but remove all books and pages.</p>
          <p v-if="type === 'linkContent'" class="functional">Duplicate the location, and include the contents as links.  This does not duplicate any books or pages, but points to the existing ones. </p>
          <p v-if="type === 'copyContent'" class="functional">Duplicate the location and all contents.  This creates a complete duplication of everything - including books and pages. </p>
        </div>
      </transition>

      <div class="buttons">
        <btn v-text="'duplicate'" :class="{ 'disabled': !['noContent','linkContent','copyContent'].includes(store.state.duplicateType)}" @click.prevent="duplicateBranch"></btn>
        <btn class="secondary" v-text="'cancel'" @click.stop="duplicateModal = false;">
        </btn>
      </div>
    </div>

    <div class="delete_section" v-if="deleteEmpty">
      <p class="dialog_header">delete</p>
      <h3>Deletion Warning</h3>
      <div class="trash_can"></div>
      <div class="buttons">
        <btn class="button secondary" v-text="'cancel'" @click="deleteEmpty = !deleteEmpty"></btn>
        <btn class="button" v-text="'confirm deletion'" @click="deleteEmptyLine"></btn>
      </div>
    </div>

    <div class="notification" id="notification" v-if="state.notification === 'edit' || state.notification === 'delete'"
      :class="{ 'edit': state.notification === 'edit', 'delete': state.notification === 'delete' }">
      <p v-if="state.notification === 'delete'">Location was deleted / edited successfully</p>
      <p v-if="state.notification === 'edit'">Location added / edited successfully</p>
      <!-- <p>Error occurred, try again later or contact<br/> support</p> -->
    </div>

    <transition name="fade">
      <div class="context_shadow" v-if="deleteNotEmpty || deleteEmpty || editLocation || addNewLocation || duplicateModal || deleteLib"
        @click.stop="deleteNotEmpty = false; deleteEmpty = false; editLocation = false; sectionRelocate = false; addNewLocation = false; duplicateModal = false; deleteLib = false">
      </div>
    </transition>

    <div class="move_place_confirm" v-if="store.state.movePlace">
      <btn class="secondary caption" @click="store.state.movePlace = false; store.state.radioButtonId = null; store.state.moveToSelect = []; store.state.moveId = null;" v-text="'cancel'"></btn>
      <btn class="caption" :class="!this.ifLocationSelected ? 'disabled' : null " v-text="'confirm'"  @click.prevent="!this.ifLocationSelecte ? moveLocation() : null"></btn>
    </div>
    <div class="move_to">
      <h3>
        Move content to:
      </h3>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { computed, watch } from 'vue';
import { inject, onMounted, reactive, ref } from 'vue';
import TreeItem from '../components/treeview/TreeItem.vue';
import btn from '../components/buttons/button.vue';
import { v4 as uuidv4 } from 'uuid';
// import LoadingOverlay from '../components/preloaders/LoadingOverlay.vue';
import whiteOverlay from '../components/preloaders/whiteOverlay.vue';
import { SuperTokensController as STC } from '../services/SuperTokensController.js';

export default {
  name: 'Select',
  inject: ['apiPath'],
  components: {
    TreeItem,
    btn,
    // LoadingOverlay,
    whiteOverlay
  },
  emits: ['onClick'],
  computed: {
    filteredList() {
      return this.transform(this.state.locationsFiltered)
    },
    ifLocationSelected() {
      if (this.$store.state.radioButtonId != null) {
        return true;
      } else if (this.$store.state.radioButtonId == null) {
        return false;
      } else {
        return false;
      }
    },
    edit() {
      return this.$store.state.edit;
    },
  },
  methods: {
    transform(arr) {
      return (JSON.parse(JSON.stringify(arr)));
    },
  },
  beforeMount() {
    this.$store.state.beforeLoad = false;
    console.log(this.$store.state.beforeLoad, 'this.$store.state.beforeLoad')
  },
  mounted() {
    this.emitter.all.clear();
    this.emitter.on('collapse_select', () => {
      this.toggle();
      this.$store.state.selectCollapse = !this.$store.state.selectCollapse;

      let exposedTreeNodes = [];
      if (!this.$store.state.selectCollapse) {
        let list = this.getLocationsList();
        if (list.length > 0) {
          list.forEach(function (location) {
            exposedTreeNodes.push(location.id);
          });
        }
      }
      localStorage.setItem('exposedTreeNodes', JSON.stringify(exposedTreeNodes));
      STC.setCurrentUserMetadata({"exposedTreeNodes": exposedTreeNodes});
      // fetch('https://auth.wave7.cloud/auth/setmetadata', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type':'application/json',
      //     'Accept':'application/json'
      //   },
      //   body: JSON.stringify({"exposedTreeNodes": exposedTreeNodes})
      // }).then((res) => res.json()).then((r) => {
      //   console.log("SET-METADATA-RESPONSE",r);
      // }).catch((error) => {
      //   console.log('Looks like there was a problem: \n', error);
      // });
    });
  },
  setup() {
    // const route = useRoute();
    const router = useRouter()
    const store = useStore();
    store.state.movePlace = false;
    watch(
      () => store.state.movePlace,
      () => {
        // Do something when props.childKey changes
        console.log('Child key changed:', store.state.movePlace);
        movePlace = store.state.movePlace;
      }
    );
    let movePlace = store.state.movePlace;
    const collapsed = ref(true);
    const root = ref({
      name: '',
      icon: '',
      isHome: true,
      children: [],
    });
    const emptyRoot = ref({
      name: '',
      icon: '',
      isHome: true,
    });
    let apiPath = '';
    let deleteEmpty = ref(false);
    let deleteLib = ref(false);
    let deleteLibNode = undefined;
    let deleteNotEmpty = ref(false);
    let editLocation = ref(false);
    let addNewLocation = ref(false);
    let sectionRelocate = ref(false);
    let duplicateModal = ref(false);
    let isDeleteChecked = ref(false);
    let nodeToMove = ref();
    let path = ref();
    let positionOfTheNode = ref(0);
    let state = reactive({
      selectVar: 'Select...',
      deleteOption: 0,
      ifNewSectionSelected: 'Select...',
      locations: [],
      nodeName: '',
      nodeLength: 0,
      locationsFiltered: [''],
      parentNode: null,
      locationToMove: null,
      locationToMoveTarget: null,
      replaceLocation: false,
      addNewLocation: false,
      newLocationName: '',
      enableSubmit: false,
    });

    const childComponent = ref();

    const collapseAll = () => {
      childComponent.value.collapse();
      collapsed.value = true;
    };
    const expandAll = () => {
      childComponent.value.expand();
      collapsed.value = false;
    };
    const moveLocation = () => {
      if (store.state.radioButtonId === null || store.state.radioButtonId === undefined || store.state.moveId == store.state.radioButtonId) {
        return;
      }
      //use it for deleting, not moving
      if (store.state.modalToComeback) {
        deleteNotEmpty.value = true;
        store.state.movePlace = false;
        return;
      }
      sendFetch(
          'entity/location/edit',
          {
            "slocationid": store.state.moveId,
            "parentid": store.state.radioButtonId,
          }
      ).then(() => {
        let parentNode = searchNodeByField(store.state.radioButtonId);
        if (parentNode.children !== undefined) {
          let locationsIds = [];
          parentNode.children.forEach(function (e) {
            if (e.id === store.state.moveToSelect[0]) {
              if (e.id == store.state.moveId) {
                locationsIds.push(e.id);
              } else if (store.state.moveToSelect[1] == 0) {
                locationsIds.push(store.state.moveId);
                locationsIds.push(e.id);
              } else if (store.state.moveToSelect[1] == 1) {
                locationsIds.push(e.id);
                locationsIds.push(store.state.moveId);
              }
            } else if (e.id != store.state.moveId) {
              locationsIds.push(e.id);
            }
            if (locationsIds.length > 0) {
              sendFetch(
                  'entity/location/resort',
                  {
                    "ids": JSON.stringify(locationsIds)
                  }
              )
            }
          });
        }
        store.state.movePlace = false;
        store.state.radioButtonId = null;
        store.state.moveToSelect = [];
        store.state.moveId = null;
      });
    };
    // const reopen = () => {
    //   store.state.modalToComeback
    // };

    async function sendFetch(url, data = null, updateList = true) {
      let formData = new FormData();
      formData.append('token', store.state.usertoken);
      if (data !== null) {
        Object.keys(data).forEach(function(key) {
          formData.append(key, data[key]);
        });
      }
      return await fetch(`${apiPath}` + url, {
        method: 'POST',
        body: formData,
      }).then((res) => res.json()).then((response) => {
        if (updateList) {
          getData(); //call to update data in the tree in case someone edits its structure at the same time
        }
        if (response.result) {
          return response.result;
        } else {
          console.log('Looks like there was a problem: \n', response.error);
          if (response.error === 'Wrong token') {
            window.location.reload();
          }
          return false;
        }
      }).catch((error) => {
        console.log('Looks like there was a problem: \n', error);
        return false;
      });
    }

    const here = () => {
      console.log(store.state.moveToSelect);
    };
    const getData = () => {
      if (apiPath === '' || apiPath === undefined || apiPath === null) {
        apiPath = inject('apiPath');
      }

      let formData = new FormData();
      formData.append('token', store.state.usertoken);
      fetch(`${apiPath}page/select`, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.json())
        .then((response) => {
          store.state.beforeLoad = true;
          console.log(store.state.beforeLoad, 'this.$store.state.beforeLoad')
          if (response.result) {
            let data = response.result;
            root.value = data.root;
            emptyRoot.value = data.emptyRoot;
          } else {
            console.log('Looks like there was a problem: \n', response.error);
            if (response.error === 'Wrong token') {
              window.location.reload();
            }
          }
        })
        .catch((error) => {
          console.log('Looks like there was a problem: \n', error);
        });
    };

    // const saveData = () => {
    //   let locations = getLocationsList();
    //   locations.forEach(function (element) { delete element.children });
    //   let formData = new FormData();
    //   formData.append('locations', JSON.stringify(locations));
    //   formData.append('token', store.state.usertoken);
    //   fetch(`${apiPath}page/tempSelectSave`, {
    //     method: 'POST',
    //     body: formData,
    //   }).then((res) => res.json()).then((response) => {
    //     if (response.result) {
    //       getData();
    //     } else {
    //       console.log('Looks like there was a problem: \n', response.error);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log('Looks like there was a problem: \n', error);
    //   });
    // };

    const toggle = () => {
      if (!collapsed.value) {
        collapseAll();
      } else {
        expandAll();
      }
    };

    const globalEdit = computed(() => store.state.edit);

    const nodeWasClicked = (node) => {
      if (node.databaseid.length !== 36){
        router.push({ path: `${node.route}` });
      } else {
        collapsed.value = false;
        let nodeFamily = getNodeFamilyPath(node);
        // if (globalEdit.value === false) {
          if (node.machinekb === '1' && ((store.state.user.role.includes('admin') || store.state.user.role.includes('bOpus_admin')) || node.permissions?.includes('view'))) {
            // console.log(node, 'node');
            router.push({ path: `${node.route}/${node.databaseid}` });
          }
        // } else {
        //   return
        // }
      }
    };

    const onMoveClicked = (node) => {
      let list = getLocationsList([],'id', node);
      if (list?.length > 0) {
        let ids = [];
        list.forEach((location) => {
          ids.push(location.id);
        });
        store.state.childrenIds = ids;
      } else {
        store.state.childrenIds = [];
      }
    };
    //     this.$router.push({ path: `${node.route}/${node.databaseid}` });

    //get rid of setAsStart function
    const setAsStart = (node) => {
      //
      // //todo get rid of {startpage} in project and use {user.metadata.startpage} instead
      // store.state.user.metadata.startpage = node.route + "/" + node.databaseid;
      // store.state.startpage = store.state.user.metadata.startpage;
      //
      // //todo get rid of {startpagename} in project and use {user.metadata.startpagename} instead
      // store.state.user.metadata.startpagename = node.name;
      // store.state.startpagename = store.state.user.metadata.startpagename;
      //
      // localStorage.setItem("bOpus_user", JSON.stringify(store.state.user));
      //
      // const path = node.route + "/" + node.databaseid;
      // store.state.startpageloaded = true;
      //
      // fetch('https://auth.wave7.cloud/auth/setmetadata', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type':'application/json',
      //     'Accept':'application/json'
      //   },
      //   body: JSON.stringify(store.state.user.metadata)
      // }).then((res) => res.json()).then((r) => {
      //   console.log("SET-METADATA-RESPONSE",r);
      // }).catch((error) => {
      //   console.log('Looks like there was a problem: \n', error);
      // });
    };
    // const addFavourite = (node) => {
    //   console.log(node?.id);
    // };
    // const createSos = (node) => {
    //   console.log(JSON.stringify(node));
    // };

    const addLocation = (node) => {
      addNewLocation.value = !addNewLocation.value;
      state.newLocationName = '';
      state.parentNode = node;
    };

    const createNode = () => {
      if (state.newLocationName === '') {
        return;
      }
      let node = searchNodeByField(state.parentNode.id);
      if (node.children === undefined) {
        node.children = [];
      }
      let newId = uuidv4();
      let children = searchNodeByField(state.parentNode.id)['children'];
      children.push(
        {
          id: newId,
          databaseid: newId,
          parentid: state.parentNode.id,
          name: state.newLocationName,
          depth: state.parentNode.depth + 1,
          'icon': '',
          visible: 1,
          route: '/machine',
          machinekb: 0,
          project: node.project,
        }
      );
      let childrenIds = [];
      children.forEach(function (e) {
        childrenIds.push(e.id);
      });
      addNewLocation.value = !addNewLocation.value;
      // note('edit');
      sendFetch(
          'entity/location/add',
          {
            "slocationid": newId,
            "parentid": state.parentNode.id,
            "Name": state.newLocationName,
            "project": node.project,
          },
          false
      ).then((response) => {
        if (response) {
          if (childrenIds.length > 0) {
            sendFetch(
                'entity/location/resort',
                {
                  "ids": JSON.stringify(childrenIds)
                }
            )
          }
        }
      }).catch((error) => {
        getData();
        console.log('Looks like there was a problem: \n', error);
      });
      //saveData(); //old save
    };

    const addLibrary = (node) => {
      node.machinekb = 1;
      const newSection = {
        img: '',
        img_disabled: '',
        title: '',
        route: '',
        desc: '',
        context: false,
      };
      newSection.img = 'url(../img/library.svg)';
      newSection.title = "Starter Section";
      newSection.desc = '0 Books';

      let formData = new FormData();
      formData.append('slocationid', node.id);
      formData.append('machinekb', node.machinekb);
      formData.append('token', store.state.usertoken);

      fetch(`${apiPath}entity/location/edit`, {
        method: 'POST',
        body: formData,
      }).then((res) => res.json()).then((response) => {
        if (response.result) {
          node.icon = 'machine';
          getData();
        } else {
          console.log('Looks like there was a problem: \n', response.error);
        }
      }).catch((error) => {
        console.log('Looks like there was a problem: \n', error);
      });

      //we don't need to add starter section anymore
      // formData = new FormData();
      // formData.append('Name', "Starter Section");
      // formData.append('slocationid', node.databaseid);
      // formData.append('Icon', 'url(../img/bopus/bopus_hat_only_grey.svg)');
      // formData.append('sortorder', '0');
      // formData.append('token', store.state.usertoken);
      // fetch(`${apiPath}entity/section/add`, {
      //   method: 'POST',
      //   body: formData,
      // }).then((res) => res.json()).then((response) => {
      //   if (response.result) {
      //     getData();
      //   } else {
      //     console.log('Looks like there was a problem: \n', response.error);
      //   }
      // }).catch((error) => {
      //   console.log('Looks like there was a problem: \n', error);
      // });
    };

    const delLibrary = (node) => {
      deleteLib.value = true;
      deleteLibNode = node;
      // node.machinekb = 0;
      // node.icon = '';
      // sendFetch(
      //     'entity/location/edit',
      //     {
      //       "slocationid": node.id,
      //       "machinekb": 0,
      //     }
      // );
    };
    const delThisLib = () => {
      deleteLibNode.machinekb = 0;
      deleteLibNode.icon = '';
      sendFetch(
          'entity/location/edit',
          {
            "slocationid": deleteLibNode.id,
            "machinekb": 0,
          }
      ).then(() => {
        deleteLib.value = false;
        deleteLibNode = undefined;
      });
    }

    const moveUp = (node) => {
      let nodeFamily = searchNodeByField(node.parentid);
      let nodeData = searchNodeByField(node.id, 'id', nodeFamily);
      let position = nodeFamily.children.findIndex(function (item) { return item.name == node.name; });
      nodeFamily.children.splice(position, 1);
      nodeFamily.children.splice(position - 1, 0, nodeData);
      //saveData(); - disabled. moveUp is deprecated
    };

    const moveDown = (node) => {
      let nodeFamily = searchNodeByField(node.parentid);
      let nodeData = searchNodeByField(node.id, 'id', nodeFamily);
      let position = nodeFamily.children.findIndex(function (item) { return item.name == node.name; });
      nodeFamily.children.splice(position, 1);
      nodeFamily.children.splice(position + 1, 0, nodeData);
      //saveData(); - disabled. moveDown is deprecated
    };

    const moveTo = (location, ifDelete) => {
      state.selectVar = location.name;
      if (ifDelete) {
        state.deleteOption = 2;
      } else {
        state.replaceLocation = true;
      }
      state.locationToMoveTarget = location;
    };

    const moveNode = () => {
      if (!state.replaceLocation && !state.enableSubmit) {
        return;
      }
      let nodeItself = state.locationToMove;
      let nodeFamily = undefined;
      let position = undefined;
      if (state.locationToMove.parentid !== 0) {
        nodeFamily = searchNodeByField(state.locationToMove.parentid);
        nodeItself = searchNodeByField(state.locationToMove.id, 'id', nodeFamily);
        position = nodeFamily.children.findIndex(function (item) {
          return item.name === state.locationToMove.name;
        });
      }
      nodeItself.name = state.nodeName;
      if (state.replaceLocation) {
        let newNodeFamily = searchNodeByField(state.locationToMoveTarget.id);
        if (newNodeFamily.children === undefined) {
          newNodeFamily.children = [];
        }
        nodeItself.parentid = newNodeFamily.id;
        newNodeFamily.children.push(nodeItself);
        if (nodeFamily !== undefined && position !== undefined) {
          nodeFamily.children.splice(position, 1);
        }
      }
      state.enableSubmit = false;
      state.selectVar = 'Select...';
      editLocation.value = !editLocation.value;
      sendFetch(
          'entity/location/edit',
          {
            "slocationid": nodeItself.id,
            "parentid": nodeItself.parentid,
            "Name": nodeItself.name,
          }
      );
      //saveData(); //old save
      clear();
    };

    const getAllElements = (excludeValues = null) => {
      if (excludeValues !== null) {
        state.locations = getLocationsList(excludeValues);
      } else {
        state.locations = getLocationsList();
      }
      state.locationsFiltered = JSON.parse(JSON.stringify(state.locations));
    };

    const editRecord = (node) => {
      clear();
      getAllElements([node.id]);
      state.locationToMove = node;
      state.nodeName = node.name;
      editLocation.value = !editLocation.value;
    };

    // const duplicateRecord = (node) => {
    //   let nodeFamily = searchNodeByField(node.parentid);
    //   let nodeItself = searchNodeByField(node.id, 'id', nodeFamily);
    //   let position = nodeFamily.children.findIndex(function (item) {
    //     return item.name == node.name;
    //   });
    //   let duplicatedNode = JSON.parse(JSON.stringify(nodeItself));
    //   duplicatedNode.name = duplicatedNode.name + ' copy';
    //   //duplicatedNode.id = uuidv4();
    //   //duplicatedNode.databaseid = duplicatedNode.id;
    //   nodeFamily.children.splice(position + 1, 0, duplicatedNode);
    //   updateNewBranch(duplicatedNode, nodeFamily.id);
    //   saveData();
    // };
    const duplicateRecord = (node) => {
      store.state.duplicateNode = node;
      duplicateModal.value = true;
    };

    const duplicateBranch = () => {
      if (store.state.duplicateNode?.id === undefined) {
        return;
      }
      let branchLocationsList = getLocationsList([],'id', searchNodeByField(store.state.duplicateNode.id));
      if (branchLocationsList.length < 1) {
        return;
      }
      let ids = {};
      branchLocationsList.forEach((location) => {
        ids[location.id] = uuidv4();
      });
      if (ids !== {} && store.state.duplicateType !== undefined) {
        sendFetch(
            'entity/location/duplicate',
            {
              "id": JSON.stringify(ids),
              "type": store.state.duplicateType
            }
        ).then(()=>{
          store.state.duplicateType = undefined;
          store.state.duplicateNode = undefined;
        });
      }
      duplicateModal.value = false;
    };

    const deleteRecord = (node) => {
      clear();
      state.locationToMove = node;
      if (node.scount > 0 || (node.children !== undefined && node.children.length > 0)) {
        getAllElements([node.id]);
        deleteNotEmpty.value = !deleteNotEmpty.value;
      } else {
        deleteEmpty.value = !deleteEmpty.value;
      }
    };

    const check = () => {
      const pattern = document.getElementsByName('delete_pattern')[0].value.toLowerCase();
      document.getElementById('delete_one').checked = true;
      if (pattern === 'delete') {
        state.deleteOption = 1;
      } else {
        state.deleteOption = 0;
      }
    };

    const deleteNode = () => {
      if (state.deleteOption === 0) {
        return;
      }
      if (store.state.moveId !== null && store.state.radioButtonId !== null) {
        state.locationToMove = searchNodeByField(store.state.moveId.toString());
        state.locationToMoveTarget = searchNodeByField(store.state.radioButtonId.toString());
      }
      if (state.locationToMoveTarget !== null) {
        if (deleteNotEmpty.value) {
          if (state.locationToMove.scount) {
            let formData = new FormData();
            formData.append('filterData', JSON.stringify({"slocationid":state.locationToMove.id}));
            formData.append('updateData', JSON.stringify({"slocationid":state.locationToMoveTarget.id}));
            formData.append('token', store.state.usertoken);
            fetch(`${apiPath}entity/section/update`, {
              method: 'POST',
              body: formData,
            }).then((res) => res.json()).then((response) => {
              if (response.result) {
                //todo may be add some notification
              } else {
                console.log('Looks like there was a problem: \n', response.error);
                if (response.error === 'Wrong token') {
                  window.location.reload();
                }
              }
            }).catch((error) => {
              console.log('Looks like there was a problem: \n', error);
            });
          }
        }
      }



      /* //OLD
      delete state.locationToMove.children;
      let nodeFamily = searchNodeByField(state.locationToMove.parentid);
      let position = nodeFamily.children.findIndex(function (item) {
        return item.id === state.locationToMove.id;
      });
      nodeFamily.children.splice(position, 1);

       */
      let parentNode = searchNodeByField(store.state.radioButtonId);
      if (parentNode !== null && parentNode.children !== undefined) {
        let locationsIds = [];


        parentNode.children.forEach(function (e) {
          if (e.id === store.state.moveToSelect[0]) {
            if (store.state.moveToSelect[1] == 0) {
              if (state.locationToMove.children && state.locationToMove.children.length > 0) {
                state.locationToMove.children.forEach(function (element) {
                  locationsIds.push(element.id);
                });
              }
              locationsIds.push(e.id);
            } else if (store.state.moveToSelect[1] == 1) {
              locationsIds.push(e.id);
              if (state.locationToMove.children && state.locationToMove.children.length > 0) {
                state.locationToMove.children.forEach(function (element) {
                  locationsIds.push(element.id);
                });
              }
            }
          } else if (e.id != store.state.moveId) {
            locationsIds.push(e.id);
          }
        });
        if (locationsIds.length > 0) {
          sendFetch(
              'entity/location/resort',
              {
                "ids": JSON.stringify(locationsIds)
              }
          )
        }

        if (state.locationToMove.children && state.locationToMove.children.length > 0) {
          state.locationToMove.children.forEach(function (element) {
            sendFetch(
                'entity/location/edit',
                {
                  "slocationid": element.id,
                  "parentid": store.state.radioButtonId,
                });
          });
        }
        store.state.movePlace = false;
        store.state.radioButtonId = null;
        store.state.moveToSelect = [];
        store.state.moveId = null;
        state.locationToMoveTarget = null;
      }

      deleteNotEmpty.value = !deleteNotEmpty.value;
      sendFetch(
          'entity/location/delete',
          {
            "slocationid": state.locationToMove.id,
          }
      )
      clear();

    };

    // const note = (type) => {
    //   if (type === 'edit') {
    //     state.notification = 'edit';
    //     setTimeout(() => state.notification = '', 5000);
    //   } if (type === 'delete') {
    //     state.notification = 'delete';
    //     setTimeout(() => state.notification = '', 5000);
    //   }
    // };

    const deleteEmptyLine = () => {
      let nodeFamily = searchNodeByField(state.locationToMove.parentid);
      let position = nodeFamily.children.findIndex(function (item) {
        return item.name == state.locationToMove.name;
      });
      nodeFamily.children.splice(position, 1);
      deleteEmpty.value = !deleteEmpty.value;
      sendFetch(
          'entity/location/delete',
          {
            "slocationid": state.locationToMove.id,
          }
      );
      //saveData(); //old save
      // note('delete');
    };

    function clear() {
      state.deleteOption = 0;
      state.ifNewSectionSelected = 'Select...';
      state.locationToMove = null;
      state.replaceLocation = false;
    }

    function getNodeFamilyPath(element) {
      let names = [], currentParentId = element.parentid;
      while (currentParentId !== 0 && currentParentId !== null) {
        let parent = searchNodeByField(currentParentId);
        names.push([element.name, `${'/machine/'}` + element.id, element.machinekb]);
        currentParentId = parent.parentid;
        element = parent;
      }
      names.push([element.elementPath[0].name, `${'/machine/'}`+element.elementPath[0].id, element.elementPath[0].machinekb]);
      names.push(["Home", "/", "1"]);
      // console.log(names);
      return names.reverse();
    }

    //function getLocationsList(excludeValues = [], excludeKey = 'id', branch = root.value, copy = true, sortorder = 0) {
    const getLocationsList = function(excludeValues = [], excludeKey = 'id', branch = root.value, copy = true, sortorder = 0) {
      if (branch.children === null || branch.children === undefined) {
        if (inArray(branch[excludeKey], excludeValues)) {
          return [];
        }
        let result;
        if (copy) {
          result = [JSON.parse(JSON.stringify(branch))];
        } else {
          result = [branch];
        }
        result[0].sortorder = sortorder;
        return result;
      }
      let i, result;
      if (inArray(branch[excludeKey], excludeValues)) {
        result = [];
      } else {
        if (copy) {
          result = [JSON.parse(JSON.stringify(branch))];
        } else {
          result = [branch];
        }
        result[0].sortorder = sortorder;
      }
      for (i = 0; i < branch.children.length; i++) {
        result = result.concat(getLocationsList(excludeValues, excludeKey, branch.children[i], copy, i));
      }
      return result;
    }

    function inArray(needle, haystack) {
      let length = haystack.length;
      for (let i = 0; i < length; i++) {
        if (haystack[i] === needle) return true;
      }
      return false;
    }

    function searchNodeByField(value, key = 'id', branch = root.value) {
      if (branch[key] === value) {
        return branch;
      } else if (branch.children != null) {
        let i, result = null;
        for (i = 0; result == null && i < branch.children.length; i++) {
          result = searchNodeByField(value, key, branch.children[i]);
        }
        return result;
      }
      return null;
    }

    function updateNewBranch(branch, parentid) {
      branch.id = uuidv4();
      branch.databaseid = branch.id;
      branch.parentid = parentid;
      if (branch.children != null) {
        for (let i = 0; i < branch.children.length; i++) {
          updateNewBranch(branch.children[i], branch.id);
        }
      }
    }

    onMounted(() => getData());

    return {
      store,
      globalEdit,
      path,
      nodeWasClicked,
      onMoveClicked,
      toggle,
      root,
      emptyRoot,
      collapsed,
      childComponent,
      getAllElements,
      getData,
      setAsStart,
      //saveData,
      // addFavourite,
      // createSos,
      state,
      addLocation,
      createNode,
      addNewLocation,
      positionOfTheNode,
      addLibrary,
      delLibrary,
      delThisLib,
      moveUp,
      moveTo,
      nodeToMove,
      moveDown,
      moveNode,
      check,
      deleteNode,
      editRecord,
      duplicateModal,
      deleteLib,
      deleteLibNode,
      deleteEmpty,
      deleteEmptyLine,
      deleteNotEmpty,
      sectionRelocate,
      editLocation,
      duplicateRecord,
      deleteRecord,
      here,
      movePlace,
      isDeleteChecked,
      moveLocation,
      duplicateBranch,
      getLocationsList,
    };
  },
};
</script>

<style lang="scss">
// .fake_element{
//   height: 49px;
//   margin: 3px 0;
//   background-color: hsla(0,0%,47.1%,.0509803922);
//   max-width: 1140px;
//   position: relative;
//   overflow: hidden;
// }
// .fake_element::before {
// 	content:'';
//   top:0;
// 	transform:translateX(100%);
// 	width: 100%;
//   max-width: 1140px;
//   height: 49px;
// 	position: absolute;
// 	z-index:1;
// 	animation: slide 1s infinite;
//   background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(128,186,232,0) 99%, rgba(125,185,232,0) 100%); /* FF3.6+ */
// 	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(50%,rgba(255,255,255,0.8)), color-stop(99%,rgba(128,186,232,0)), color-stop(100%,rgba(125,185,232,0))); /* Chrome,Safari4+ */
// 	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Chrome10+,Safari5.1+ */
// 	background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Opera 11.10+ */
// 	background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* IE10+ */
// 	background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* W3C */
// 	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
// }
// /* animation */
// @keyframes slide {
// 	0% {transform:translateX(-100%);}
// 	100% {transform:translateX(100%);}
// }


.move_to{
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  background-color: #fff;
  height: 38px;
  h3{
    text-indent: 16px;
    margin: 0;
    height: 38px;
    line-height: 38px;
  }
}
.move_place_confirm{
  position: fixed;
  bottom: 0px;
  z-index: 3;
  height: 64px;
  right: 0px;
  left: 0px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 16px;
  .button{
    height: 48px;
    line-height: 48px;
    flex: 1;
  }
  .button:first-child{
    margin-right: 16px;
  }
}
.Tree {
  position: absolute;
  bottom: 72px;
  top: 64px;
  right: 0px;
  left: 0px;
  overflow-y: scroll;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background-color: #f2f2f2;
  // @media screen and (min-width: 27 01px) and (max-width: 3840px){
  //   bottom: 169px;
  //   top: 150px;
  //   padding: 0 100px 30px;
  // }
  // DESKTOP
  // @media screen and (min-width: 1025px) and (max-width: 27 00px){
  @media screen and (min-width: 1025px){
    padding: 0 50px 30px;
  }
  // LAPTOP
  @media screen and (min-width: 901px) and (max-width: 1024px){
    padding: 0 40px 30px;
  }
  // TABLET
  @media screen and (min-width: 481px) and (max-width: 900px){
    padding: 0 30px 30px;
    bottom: 64px;
    top: 52px;
  }
  // MOBILE
  @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    padding: 0 15px 15px;
  }
  &__main {
    // @media screen and (min-width: 27 01px) and (max-width: 3840px){
    //   max-width: 1140px;
    // }
    // DESKTOP
    // @media screen and (min-width: 1025px) and (max-width: 27 00px){
    @media screen and (min-width: 1025px){
      max-width: 1140px;
    }
    // LAPTOP
    @media screen and (min-width: 901px) and (max-width: 1024px){
      max-width: 907px;
    }
    // TABLET
    @media screen and (min-width: 481px) and (max-width: 900px){
    }
    // MOBILE
    @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    }
  }

  &__panel {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    display: flex;
    flex-wrap: nowrap;
    margin-top: 0px;
  }

  &__title {
    font-weight: 600;
    color: #252525;

    @media screen and (max-width: 599px) and (orientation: portrait) {
      margin: 0 0 16px 0;
      // font-size: 26px;
    }

    @media screen and (max-height: 599px) and (orientation: landscape) {
      margin: 0 0 16px 0;
      // font-size: 26px;
    }
  }

  .delete_section {
    .trash_can {
      height: 250px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(../../public/img/trash.png);
    }
  }

  .duplicate_modal,
  .delete_section,
  .delete_section_with,
  .edit_section,
  .add_section {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0px;
    bottom: 0px;
    right: 0px;
    // width: calc(100% - 1200px);
    width: 500px;
    box-sizing: border-box;
    padding: 15px 30px 30px 30px;
    background-color: #fff;
    z-index: 3;
    .dialog_header{
      height: 48px;
      line-height: 48px;
      font-size: 1rem;
      font-weight: 500;
      text-align: center;
      color: #444;
      text-transform: uppercase;
      position: relative;
      width: 100%;
      margin: 0px;
    }
    .dialog_header::before,.dialog_header::after{
      content: '';
      width: calc(50% - 90px);
      height: 5px;
      position: absolute;
      background-color: #2525251A;
      top: calc(50% - 2.5px);
      border-radius: 3px;
    }
    .dialog_header_long::before,.dialog_header_long::after{
      width: calc(50% - 105px);
    }
    
    .dialog_header::before{
      left: 15px;
    }
    .dialog_header::after{
      right: 15px;
    }
    .charcount{
      color: #787878;
      font-size: 12px;
      line-height: 18px;
      float: right;
      // margin-bottom: -15px;
    }
    h3 {
      margin-top: 0px;
    }

    .edit_input {
      border-radius: 8px;
      height: 40px;
      max-width: 100%;
      width: 100%;
      line-height: 40px;
      border: none;
      outline: none;
      color: #787878;
      text-indent: 16px;
      background-color: rgba(120, 120, 120, 0.05);
    }

    .buttons {
      margin-top: auto;
      text-align: center;
      padding-top: 22px;
      position: relative;
      .button {
        max-width: 264px;
        width: calc(50% - 8px);
      }
      .button:first-child {
        margin-right: 16px;
      }
    }
    
    .buttons::before{
      content: '';
      position: absolute;
      height: 3px;
      border-radius: 1.5px;
      width: 100%;
      top: -28px;
      left: 0px;
      right: 0px;
      background-color: #2525251A;
    }
  }

  .delete_section_with,
  .edit_section {
    .advise {
      padding-left: 75px;
      background-repeat: no-repeat;
      background-position: 0px center;
      background-image: url(../../public/img/advise.svg);
      line-height: 22px;
      margin-bottom: 40px;
    }

    p {
      color: #787878;
    }

    .delete_one,
    .delete_two {
      // padding: 16px 16px 16px 44px;
      display: inline-block;
      width: -moz-available;
      width: -webkit-fill-available;
      width: fill-available;
      border-radius: 8px;
      position: relative;
    }

      .check_selected {
        color: #252525;
        font-weight: 500;
      }

      p {
        display: inline-block;
        margin: 0;
      }

      input:not([type="radio"]) {
        width: -moz-available;
        width: -webkit-fill-available;
        width: fill-available;
        height: 40px;
        line-height: 40px;
        border-radius: 8px;
        outline: none;
        border: none;
        margin-top: 20px;
        color: #787878;
      }

      input[type="radio"] {
        position: absolute;
        left: 15px;
      }

      .choose_section {
        overflow: hidden;

        >p {
          display: block;
          margin: 0px;
          height: 40px;
          line-height: 40px;
          border-radius: 8px;
          background-color: #fff;
          padding-left: 16px;
          color: #bcbcbc;
          margin-top: 20px;
        }

        .section_list {
          background-color: #fff;
          border-radius: 0 0 8px 8px;
          max-height: 200px;
          overflow-y: scroll;

          p {
            display: block;
            margin: 0px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            cursor: pointer;
            transition: all .3s;
          }

          p:hover {
            padding-left: 40px;
          }

          p:not(:last-child) {
            border-bottom: dashed 1px #bcbcbc;
          }
        }
    }

    .delete_one {
      margin-bottom: 16px;
    }
  }
  .duplicate_modal,
  .delete_section_with{
    h3{
      margin: 0px;
    }
      p{
        color: #787878;
      }
    .option{
      display: block;
      padding: 16px 30px 16px 90px;
      position: relative;
      margin-left: -32px;
      margin-right: -32px;
      @media screen and (max-width: 599px) and (orientation: portrait),(max-height: 599px) and (orientation: landscape){
        margin-left: -20px;
        margin-right: -20px;
      }
      p{
        color: #787878;
        margin: 0px;
      }
      input[type="radio"]{
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      label{
        height: 100%;
        width: 100%;
        display: block;
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        z-index: 2;
      }
      .radiobutton {
        position: absolute;
        left: 33px;
        height: 40px;
        width: 40px;
        background-image: url(../../public/img/select/radio-off.svg);
        background-repeat: no-repeat;
        background-size: 100%;
        z-index: 1;
      }
      input[type="radio"]:checked ~ .radiobutton{
        background-image: url(../../public/img/select/radio-on.svg);
      }
    }
    input[type="radio"]:checked + label{
      background-color: #099F450D;
    }
    .option:nth-child(4),.option:nth-child(5),.option:nth-child(6){
      border-top: 1px solid #2525251A;
    }
  }


  .delete_section_with{
      p{
        padding: 9.5px 0;
      }
      input[type="radio"]{
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      input:not([type="radio"])::placeholder{
        color: #fff;
        font-size: 1rem;
      }
      input:not([type="radio"]) {
        line-height: 41px;
        position: relative;
        width: calc(100% + 60px);
        left: -60px;
        text-align: left;
        text-indent: 16px;
        background-color: #F44336;
        color: #fff;
      }
      input[type="radio"]:checked + label{
        background-color: #099F450D;
      }
    .button{
      width: 100%;
    }
    
  }

  .context_shadow {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2;
    bottom: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
  }
}

.visible {
  display: block;
  position: relative;
  margin-top: 10px;

  input[type="checkbox"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    color: #787878;
    font-size: 14px;
    height: 40px;
    padding-left: 25px;
  }

  .checkbox {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #787878;
    border-radius: 4px;
    margin-right: 9px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: 0px;
    top: 50%;
    margin-top: -8px;
  }

  input[type="checkbox"]:checked+.checkbox {
    background-image: url(../../public/img/check.svg);
    background-color: #787878;
  }
}

.notification {
  width: 425px;
  height: 125px;
  box-shadow: 0px -2px 20px 1px rgba(37, 37, 37, 0.04);
  border-radius: 16px;
  background: rgba(255, 255, 255, 1);
  position: fixed;
  top: 125px;
  left: 50%;
  margin: 0 -212px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 95%;
  background-position: 27px center;
  background-repeat: no-repeat;

  p {
    margin: 0px;
    color: #252525;
    padding-left: 88px;
    line-height: 24px;
    font-size: 14px;
  }
}

.notification.delete {
  background-image: url(../../public/img/select/trash.png);
}

.notification.edit {
  background-image: url(../../public/img/select/edit.png);
}

.notification.error {
  background-image: url(../../public/img/select/error.png);
}

.icon-rotate {
  transform: rotate(180deg);
}

.slide-fade-more-enter-active,
.slide-updown-enter-active,
.slide-updown-leave-active,
.slide-fade-more-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-more-enter-from,
.slide-fade-more-leave-to {
  margin-bottom: -120px;
}

.slide-updown-enter-from,
.slide-updown-leave-to {
  margin-top: -50px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1370px) and (min-height: 930px) and (orientation: portrait) {
  .Tree {
    top: 65px;
    .delete_section,
    .delete_section_with,
    .edit_section,
    .add_section {
      position: fixed;
      background-color: #fff;
      padding: 32px;
      box-sizing: border-box;
      border-radius: 16px;
      z-index: 3;

    }
    &__panel {
      margin-top: 0px;
    }
  }
}
@media screen and (max-height: 1024px) and (min-height: 600px) and (max-width: 1366px) and (min-width: 961px) and (orientation: landscape) {
  .Tree {
    &__panel {
      margin-top: 0px;
    }
  }
}

@media screen and (max-width: 599px) and (orientation: portrait),
screen and (max-height: 500px) and (orientation: landscape) {
  .Tree {
    top: 44px;
    bottom: 64px;
    padding-top: 20px;
    &__panel{
      display: block;
      margin-bottom: 0px;
      margin-top: 0px;
    }
    // &__panel-button {
    //   font-size: 12px;
    //   display: block;
    //   height: 24px;
    //   line-height: 24px;
    //   img{
    //     position: absolute;
    //   }
    // }
    .add_section {
      input:not([type=checkbox]){
        padding-left: 0px;
      }
    }
    &__title{
      // font-size: 22px;
      // line-height: 32px;
      margin-bottom: 20px;
      width: 100%;
    }
    .edit_section{
      .delete_two{
        p{
          margin-bottom: 10px;
        }
      }
    }
    .duplicate_modal,
    .delete_section,
    .delete_section_with,
    .edit_section,
    .add_section {      
      min-width: auto;
      max-width: none;
      width: 100%;
      top: initial;
      bottom: 0px;
      right: 0px;
      padding: 0px 30px 16px 30px;
      border-radius: 16px 16px 0 0;
      .buttons{
        margin-top: 22px;
      }
      .buttons::before{
        top: 0px;
      }
    }
    .delete_section_with{
      .option{
        margin-left: -32px;
        margin-right: -32px;
      }
    }
  }
}

  @media screen and (max-width: 599px) and (orientation: portrait) {
    .Tree{
      .delete_section,
      .delete_section_with,
      .edit_section,
      .add_section {
        box-sizing: border-box;
        z-index: 3;
        .trash_can {
          height: 120px;
          background-size: contain;
        }
      }
      .delete_section_with{
        h3{
          margin-top: 0px;
          margin-bottom: 16px;
        }
      }
    }
  }

  @media screen and (max-height: 500px) and (orientation: landscape) {
    .Tree{
      .delete_section,
      .delete_section_with,
      .edit_section,
      .add_section {
        max-width: 90%;
        left: 5%;
        margin-left: auto;
        position: fixed;
        background-color: #fff;
        width: 60%;
        padding: 16px;
        box-sizing: border-box;
        border-radius: 16px;
        top: 2%;
        z-index: 3;
        left: 20%;
        margin-left: auto;
        max-height: 100%;
        overflow-x: scroll;

        .buttons {
          .button {
            width: 46% !important;
            padding: 0 !important;
          }
        }

        .trash_can {
          height: 120px;
          background-size: contain;
        }
      }
    }
  }
</style>